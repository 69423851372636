/* tslint:disable */
/* eslint-disable */
/**
 * Core Tasks API
 * MagMutual Core Tasks API
 *
 * The version of the OpenAPI document: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AggregateControlAll } from '../models';
// @ts-ignore
import { AggregateControlEssential } from '../models';
// @ts-ignore
import { AggregateControlReviewAll } from '../models';
// @ts-ignore
import { AggregateControlReviewEssential } from '../models';
// @ts-ignore
import { AggregateFieldAll } from '../models';
// @ts-ignore
import { AggregateFieldEssential } from '../models';
// @ts-ignore
import { AggregateFieldValueAll } from '../models';
// @ts-ignore
import { AggregateFieldValueEssential } from '../models';
// @ts-ignore
import { AggregateStep } from '../models';
// @ts-ignore
import { AggregateStepAll } from '../models';
// @ts-ignore
import { AggregateStepEssential } from '../models';
// @ts-ignore
import { AggregateStepReviewAll } from '../models';
// @ts-ignore
import { AggregateStepReviewEssential } from '../models';
// @ts-ignore
import { Control } from '../models';
// @ts-ignore
import { ControlIdEssential } from '../models';
// @ts-ignore
import { ControlReview } from '../models';
// @ts-ignore
import { ControlReviewDocument } from '../models';
// @ts-ignore
import { ControlReviewDocumentEssential } from '../models';
// @ts-ignore
import { ControlReviewDocumentIdEssential } from '../models';
// @ts-ignore
import { ControlReviewEssential } from '../models';
// @ts-ignore
import { ControlReviewIdEssential } from '../models';
// @ts-ignore
import { ControlReviewRevision } from '../models';
// @ts-ignore
import { ControlReviewRevisionEssential } from '../models';
// @ts-ignore
import { ControlReviewRevisionIdEssential } from '../models';
// @ts-ignore
import { ControlReviewStatus } from '../models';
// @ts-ignore
import { ControlReviewStatusEssential } from '../models';
// @ts-ignore
import { ControlReviewStatusIdEssential } from '../models';
// @ts-ignore
import { ControlRevision } from '../models';
// @ts-ignore
import { ControlRevisionEssential } from '../models';
// @ts-ignore
import { ControlRevisionIdEssential } from '../models';
// @ts-ignore
import { ControlStep } from '../models';
// @ts-ignore
import { ControlStepEssential } from '../models';
// @ts-ignore
import { ControlStepIdEssential } from '../models';
// @ts-ignore
import { EmailParams } from '../models';
// @ts-ignore
import { Field } from '../models';
// @ts-ignore
import { FieldEssential } from '../models';
// @ts-ignore
import { FieldGroup } from '../models';
// @ts-ignore
import { FieldGroupEssential } from '../models';
// @ts-ignore
import { FieldGroupIdEssential } from '../models';
// @ts-ignore
import { FieldIdEssential } from '../models';
// @ts-ignore
import { FieldRevision } from '../models';
// @ts-ignore
import { FieldRevisionEssential } from '../models';
// @ts-ignore
import { FieldRevisionIdEssential } from '../models';
// @ts-ignore
import { FieldType } from '../models';
// @ts-ignore
import { FieldTypeEssential } from '../models';
// @ts-ignore
import { FieldTypeIdEssential } from '../models';
// @ts-ignore
import { FieldValue } from '../models';
// @ts-ignore
import { FieldValueEssential } from '../models';
// @ts-ignore
import { FieldValueIdEssential } from '../models';
// @ts-ignore
import { FieldValueRevision } from '../models';
// @ts-ignore
import { FieldValueRevisionEssential } from '../models';
// @ts-ignore
import { FieldValueRevisionIdEssential } from '../models';
// @ts-ignore
import { Filter } from '../models';
// @ts-ignore
import { FilterEssential } from '../models';
// @ts-ignore
import { FilterIdEssential } from '../models';
// @ts-ignore
import { FindByColumnQueryUUID } from '../models';
// @ts-ignore
import { GroupAssignment } from '../models';
// @ts-ignore
import { GroupAssignmentEssential } from '../models';
// @ts-ignore
import { GroupAssignmentIdEssential } from '../models';
// @ts-ignore
import { JobAid } from '../models';
// @ts-ignore
import { Step } from '../models';
// @ts-ignore
import { StepField } from '../models';
// @ts-ignore
import { StepFieldEssential } from '../models';
// @ts-ignore
import { StepFieldIdEssential } from '../models';
// @ts-ignore
import { StepIdEssential } from '../models';
// @ts-ignore
import { StepKind } from '../models';
// @ts-ignore
import { StepKindEssential } from '../models';
// @ts-ignore
import { StepKindIdEssential } from '../models';
// @ts-ignore
import { StepLink } from '../models';
// @ts-ignore
import { StepLinkEssential } from '../models';
// @ts-ignore
import { StepLinkIdEssential } from '../models';
// @ts-ignore
import { StepReview } from '../models';
// @ts-ignore
import { StepReviewIdEssential } from '../models';
// @ts-ignore
import { StepReviewLink } from '../models';
// @ts-ignore
import { StepReviewLinkEssential } from '../models';
// @ts-ignore
import { StepReviewLinkIdEssential } from '../models';
// @ts-ignore
import { StepReviewRevision } from '../models';
// @ts-ignore
import { StepReviewRevisionEssential } from '../models';
// @ts-ignore
import { StepReviewRevisionIdEssential } from '../models';
// @ts-ignore
import { StepReviewStatus } from '../models';
// @ts-ignore
import { StepReviewStatusEssential } from '../models';
// @ts-ignore
import { StepReviewStatusIdEssential } from '../models';
// @ts-ignore
import { StepRevision } from '../models';
// @ts-ignore
import { StepRevisionEssential } from '../models';
// @ts-ignore
import { StepRevisionIdEssential } from '../models';
// @ts-ignore
import { UserGroup } from '../models';
// @ts-ignore
import { UserGroupEssential } from '../models';
// @ts-ignore
import { UserGroupIdEssential } from '../models';
// @ts-ignore
import { Zone } from '../models';
// @ts-ignore
import { ZoneIdEssential } from '../models';
// @ts-ignore
import { ZoneLevel } from '../models';
// @ts-ignore
import { ZoneLevelEssential } from '../models';
// @ts-ignore
import { ZoneLevelIdEssential } from '../models';
// @ts-ignore
import { ZoneOwner } from '../models';
// @ts-ignore
import { ZoneOwnerEssential } from '../models';
// @ts-ignore
import { ZoneOwnerIdEssential } from '../models';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AggregateControlEssential} aggregateControlEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerCreateAggregate: async (aggregateControlEssential: AggregateControlEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateControlEssential' is not null or undefined
            assertParamExists('aggregateControlControllerCreateAggregate', 'aggregateControlEssential', aggregateControlEssential)
            const localVarPath = `/aggregate/control/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateControlEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAggregate: async (id: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateControlControllerGetAggregate', 'id', id)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateControlControllerGetAggregate', 'instant', instant)
            const localVarPath = `/aggregate/control/get/{id}/{instant}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAllAggregate: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateControlControllerGetAllAggregate', 'instant', instant)
            const localVarPath = `/aggregate/control/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} step 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAllAggregateByStep: async (step: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'step' is not null or undefined
            assertParamExists('aggregateControlControllerGetAllAggregateByStep', 'step', step)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateControlControllerGetAllAggregateByStep', 'instant', instant)
            const localVarPath = `/aggregate/control/get-by-step/{step}/{instant}`
                .replace(`{${"step"}}`, encodeURIComponent(String(step)))
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerRemoveAggregate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateControlControllerRemoveAggregate', 'id', id)
            const localVarPath = `/aggregate/control/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateControlAll} aggregateControlAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerUpdateAggregate: async (aggregateControlAll: AggregateControlAll, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateControlAll' is not null or undefined
            assertParamExists('aggregateControlControllerUpdateAggregate', 'aggregateControlAll', aggregateControlAll)
            const localVarPath = `/aggregate/control/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateControlAll, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateControlReviewEssential} aggregateControlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerCreateAggregate: async (aggregateControlReviewEssential: AggregateControlReviewEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateControlReviewEssential' is not null or undefined
            assertParamExists('aggregateControlReviewControllerCreateAggregate', 'aggregateControlReviewEssential', aggregateControlReviewEssential)
            const localVarPath = `/aggregate/control-review/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateControlReviewEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerGetAggregate: async (id: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateControlReviewControllerGetAggregate', 'id', id)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateControlReviewControllerGetAggregate', 'instant', instant)
            const localVarPath = `/aggregate/control-review/get/{id}/{instant}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerGetAllAggregate: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateControlReviewControllerGetAllAggregate', 'instant', instant)
            const localVarPath = `/aggregate/control-review/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerRemoveAggregate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateControlReviewControllerRemoveAggregate', 'id', id)
            const localVarPath = `/aggregate/control-review/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateControlReviewAll} aggregateControlReviewAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerUpdateAggregate: async (aggregateControlReviewAll: AggregateControlReviewAll, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateControlReviewAll' is not null or undefined
            assertParamExists('aggregateControlReviewControllerUpdateAggregate', 'aggregateControlReviewAll', aggregateControlReviewAll)
            const localVarPath = `/aggregate/control-review/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateControlReviewAll, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateFieldEssential} aggregateFieldEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldControllerCreateAggregate: async (aggregateFieldEssential: AggregateFieldEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateFieldEssential' is not null or undefined
            assertParamExists('aggregateFieldControllerCreateAggregate', 'aggregateFieldEssential', aggregateFieldEssential)
            const localVarPath = `/aggregate/field/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateFieldEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldControllerGetAggregate: async (id: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateFieldControllerGetAggregate', 'id', id)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateFieldControllerGetAggregate', 'instant', instant)
            const localVarPath = `/aggregate/field/get/{id}/{instant}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldControllerGetAllAggregate: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateFieldControllerGetAllAggregate', 'instant', instant)
            const localVarPath = `/aggregate/field/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldControllerRemoveAggregate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateFieldControllerRemoveAggregate', 'id', id)
            const localVarPath = `/aggregate/field/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateFieldAll} aggregateFieldAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldControllerUpdateAggregate: async (aggregateFieldAll: AggregateFieldAll, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateFieldAll' is not null or undefined
            assertParamExists('aggregateFieldControllerUpdateAggregate', 'aggregateFieldAll', aggregateFieldAll)
            const localVarPath = `/aggregate/field/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateFieldAll, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateFieldValueEssential} aggregateFieldValueEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldValueControllerCreateAggregate: async (aggregateFieldValueEssential: AggregateFieldValueEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateFieldValueEssential' is not null or undefined
            assertParamExists('aggregateFieldValueControllerCreateAggregate', 'aggregateFieldValueEssential', aggregateFieldValueEssential)
            const localVarPath = `/aggregate/field-value/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateFieldValueEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldValueControllerGetAggregate: async (id: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateFieldValueControllerGetAggregate', 'id', id)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateFieldValueControllerGetAggregate', 'instant', instant)
            const localVarPath = `/aggregate/field-value/get/{id}/{instant}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldValueControllerGetAllAggregate: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateFieldValueControllerGetAllAggregate', 'instant', instant)
            const localVarPath = `/aggregate/field-value/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldValueControllerRemoveAggregate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateFieldValueControllerRemoveAggregate', 'id', id)
            const localVarPath = `/aggregate/field-value/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateFieldValueAll} aggregateFieldValueAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldValueControllerUpdateAggregate: async (aggregateFieldValueAll: AggregateFieldValueAll, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateFieldValueAll' is not null or undefined
            assertParamExists('aggregateFieldValueControllerUpdateAggregate', 'aggregateFieldValueAll', aggregateFieldValueAll)
            const localVarPath = `/aggregate/field-value/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateFieldValueAll, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateStepEssential} aggregateStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepControllerCreateAggregate: async (aggregateStepEssential: AggregateStepEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateStepEssential' is not null or undefined
            assertParamExists('aggregateStepControllerCreateAggregate', 'aggregateStepEssential', aggregateStepEssential)
            const localVarPath = `/aggregate/step/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateStepEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepControllerGetAggregate: async (id: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateStepControllerGetAggregate', 'id', id)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateStepControllerGetAggregate', 'instant', instant)
            const localVarPath = `/aggregate/step/get/{id}/{instant}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepControllerGetAllAggregate: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateStepControllerGetAllAggregate', 'instant', instant)
            const localVarPath = `/aggregate/step/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepControllerRemoveAggregate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateStepControllerRemoveAggregate', 'id', id)
            const localVarPath = `/aggregate/step/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateStepAll} aggregateStepAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepControllerUpdateAggregate: async (aggregateStepAll: AggregateStepAll, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateStepAll' is not null or undefined
            assertParamExists('aggregateStepControllerUpdateAggregate', 'aggregateStepAll', aggregateStepAll)
            const localVarPath = `/aggregate/step/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateStepAll, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateStepReviewEssential} aggregateStepReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepReviewControllerCreateAggregate: async (aggregateStepReviewEssential: AggregateStepReviewEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateStepReviewEssential' is not null or undefined
            assertParamExists('aggregateStepReviewControllerCreateAggregate', 'aggregateStepReviewEssential', aggregateStepReviewEssential)
            const localVarPath = `/aggregate/step-review/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateStepReviewEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepReviewControllerGetAggregate: async (id: string, instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateStepReviewControllerGetAggregate', 'id', id)
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateStepReviewControllerGetAggregate', 'instant', instant)
            const localVarPath = `/aggregate/step-review/get/{id}/{instant}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepReviewControllerGetAllAggregate: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('aggregateStepReviewControllerGetAllAggregate', 'instant', instant)
            const localVarPath = `/aggregate/step-review/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepReviewControllerRemoveAggregate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('aggregateStepReviewControllerRemoveAggregate', 'id', id)
            const localVarPath = `/aggregate/step-review/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregateStepReviewAll} aggregateStepReviewAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepReviewControllerUpdateAggregate: async (aggregateStepReviewAll: AggregateStepReviewAll, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateStepReviewAll' is not null or undefined
            assertParamExists('aggregateStepReviewControllerUpdateAggregate', 'aggregateStepReviewAll', aggregateStepReviewAll)
            const localVarPath = `/aggregate/step-review/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregateStepReviewAll, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerCreateEntity: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('controlControllerCreateEntity', 'body', body)
            const localVarPath = `/control/control/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlControllerGetById', 'id', id)
            const localVarPath = `/control/control/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlIdEssential} controlIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerUpdateEntity: async (controlIdEssential: ControlIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlIdEssential' is not null or undefined
            assertParamExists('controlControllerUpdateEntity', 'controlIdEssential', controlIdEssential)
            const localVarPath = `/control/control/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewEssential} controlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerCreateEntity: async (controlReviewEssential: ControlReviewEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewEssential' is not null or undefined
            assertParamExists('controlReviewControllerCreateEntity', 'controlReviewEssential', controlReviewEssential)
            const localVarPath = `/control/control-review/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-review/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewControllerGetById', 'id', id)
            const localVarPath = `/control/control-review/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewIdEssential} controlReviewIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerUpdateEntity: async (controlReviewIdEssential: ControlReviewIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewIdEssential' is not null or undefined
            assertParamExists('controlReviewControllerUpdateEntity', 'controlReviewIdEssential', controlReviewIdEssential)
            const localVarPath = `/control/control-review/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewDocumentEssential} controlReviewDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerCreateEntity: async (controlReviewDocumentEssential: ControlReviewDocumentEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewDocumentEssential' is not null or undefined
            assertParamExists('controlReviewDocumentControllerCreateEntity', 'controlReviewDocumentEssential', controlReviewDocumentEssential)
            const localVarPath = `/control/control-review-document/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewDocumentEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewDocumentControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-review-document/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-review-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewDocumentControllerGetById', 'id', id)
            const localVarPath = `/control/control-review-document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewDocumentIdEssential} controlReviewDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerUpdateEntity: async (controlReviewDocumentIdEssential: ControlReviewDocumentIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewDocumentIdEssential' is not null or undefined
            assertParamExists('controlReviewDocumentControllerUpdateEntity', 'controlReviewDocumentIdEssential', controlReviewDocumentIdEssential)
            const localVarPath = `/control/control-review-document/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewDocumentIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewRevisionEssential} controlReviewRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerCreateEntity: async (controlReviewRevisionEssential: ControlReviewRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewRevisionEssential' is not null or undefined
            assertParamExists('controlReviewRevisionControllerCreateEntity', 'controlReviewRevisionEssential', controlReviewRevisionEssential)
            const localVarPath = `/control/control-review-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-review-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('controlReviewRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/control/control-review-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-review-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('controlReviewRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/control/control-review-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewRevisionControllerGetById', 'id', id)
            const localVarPath = `/control/control-review-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/control/control-review-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewRevisionIdEssential} controlReviewRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerUpdateEntity: async (controlReviewRevisionIdEssential: ControlReviewRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewRevisionIdEssential' is not null or undefined
            assertParamExists('controlReviewRevisionControllerUpdateEntity', 'controlReviewRevisionIdEssential', controlReviewRevisionIdEssential)
            const localVarPath = `/control/control-review-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewStatusEssential} controlReviewStatusEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerCreateEntity: async (controlReviewStatusEssential: ControlReviewStatusEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewStatusEssential' is not null or undefined
            assertParamExists('controlReviewStatusControllerCreateEntity', 'controlReviewStatusEssential', controlReviewStatusEssential)
            const localVarPath = `/control/control-review-status/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewStatusEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewStatusControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-review-status/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-review-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlReviewStatusControllerGetById', 'id', id)
            const localVarPath = `/control/control-review-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlReviewStatusIdEssential} controlReviewStatusIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerUpdateEntity: async (controlReviewStatusIdEssential: ControlReviewStatusIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlReviewStatusIdEssential' is not null or undefined
            assertParamExists('controlReviewStatusControllerUpdateEntity', 'controlReviewStatusIdEssential', controlReviewStatusIdEssential)
            const localVarPath = `/control/control-review-status/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlReviewStatusIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlRevisionEssential} controlRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerCreateEntity: async (controlRevisionEssential: ControlRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlRevisionEssential' is not null or undefined
            assertParamExists('controlRevisionControllerCreateEntity', 'controlRevisionEssential', controlRevisionEssential)
            const localVarPath = `/control/control-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('controlRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/control/control-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('controlRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/control/control-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlRevisionControllerGetById', 'id', id)
            const localVarPath = `/control/control-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/control/control-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerUpdateEntity: async (controlRevisionIdEssential: ControlRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlRevisionIdEssential' is not null or undefined
            assertParamExists('controlRevisionControllerUpdateEntity', 'controlRevisionIdEssential', controlRevisionIdEssential)
            const localVarPath = `/control/control-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlStepEssential} controlStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerCreateEntity: async (controlStepEssential: ControlStepEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlStepEssential' is not null or undefined
            assertParamExists('controlStepControllerCreateEntity', 'controlStepEssential', controlStepEssential)
            const localVarPath = `/control/control-step/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlStepEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlStepControllerDeleteEntity', 'id', id)
            const localVarPath = `/control/control-step/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('controlStepControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/control/control-step/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/control/control-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('controlStepControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/control/control-step/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlStepControllerGetById', 'id', id)
            const localVarPath = `/control/control-step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('controlStepControllerRemoveEntity', 'id', id)
            const localVarPath = `/control/control-step/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ControlStepIdEssential} controlStepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerUpdateEntity: async (controlStepIdEssential: ControlStepIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlStepIdEssential' is not null or undefined
            assertParamExists('controlStepControllerUpdateEntity', 'controlStepIdEssential', controlStepIdEssential)
            const localVarPath = `/control/control-step/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(controlStepIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveControls: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/import-controls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} zone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveSteps: async (zone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zone' is not null or undefined
            assertParamExists('coreTasksApiControllerImportAndSaveSteps', 'zone', zone)
            const localVarPath = `/import-steps/{zone}`
                .replace(`{${"zone"}}`, encodeURIComponent(String(zone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerPing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayDataControllerGetControls: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('displayDataControllerGetControls', 'instant', instant)
            const localVarPath = `/display/controls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (instant !== undefined) {
                localVarQueryParameter['instant'] = (instant as any instanceof Date) ?
                    (instant as any).toISOString() :
                    instant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayDataControllerGetStepFields: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('displayDataControllerGetStepFields', 'instant', instant)
            const localVarPath = `/display/step-fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (instant !== undefined) {
                localVarQueryParameter['instant'] = (instant as any instanceof Date) ?
                    (instant as any).toISOString() :
                    instant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} to 
         * @param {string} subject 
         * @param {EmailParams} emailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSendEmail: async (to: Array<string>, subject: string, emailParams: EmailParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'to' is not null or undefined
            assertParamExists('emailControllerSendEmail', 'to', to)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('emailControllerSendEmail', 'subject', subject)
            // verify required parameter 'emailParams' is not null or undefined
            assertParamExists('emailControllerSendEmail', 'emailParams', emailParams)
            const localVarPath = `/notification/email/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (to) {
                localVarQueryParameter['to'] = to;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldEssential} fieldEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerCreateEntity: async (fieldEssential: FieldEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldEssential' is not null or undefined
            assertParamExists('fieldControllerCreateEntity', 'fieldEssential', fieldEssential)
            const localVarPath = `/process/field/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/field/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/field`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldControllerGetById', 'id', id)
            const localVarPath = `/process/field/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldIdEssential} fieldIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerUpdateEntity: async (fieldIdEssential: FieldIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldIdEssential' is not null or undefined
            assertParamExists('fieldControllerUpdateEntity', 'fieldIdEssential', fieldIdEssential)
            const localVarPath = `/process/field/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldGroupEssential} fieldGroupEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGroupControllerCreateEntity: async (fieldGroupEssential: FieldGroupEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldGroupEssential' is not null or undefined
            assertParamExists('fieldGroupControllerCreateEntity', 'fieldGroupEssential', fieldGroupEssential)
            const localVarPath = `/process/field-group/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldGroupEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGroupControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldGroupControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/field-group/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGroupControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/field-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGroupControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldGroupControllerGetById', 'id', id)
            const localVarPath = `/process/field-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldGroupIdEssential} fieldGroupIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGroupControllerUpdateEntity: async (fieldGroupIdEssential: FieldGroupIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldGroupIdEssential' is not null or undefined
            assertParamExists('fieldGroupControllerUpdateEntity', 'fieldGroupIdEssential', fieldGroupIdEssential)
            const localVarPath = `/process/field-group/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldGroupIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldRevisionEssential} fieldRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerCreateEntity: async (fieldRevisionEssential: FieldRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldRevisionEssential' is not null or undefined
            assertParamExists('fieldRevisionControllerCreateEntity', 'fieldRevisionEssential', fieldRevisionEssential)
            const localVarPath = `/process/field-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/field-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('fieldRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/field-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/field-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('fieldRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/field-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldRevisionControllerGetById', 'id', id)
            const localVarPath = `/process/field-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/field-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldRevisionIdEssential} fieldRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerUpdateEntity: async (fieldRevisionIdEssential: FieldRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldRevisionIdEssential' is not null or undefined
            assertParamExists('fieldRevisionControllerUpdateEntity', 'fieldRevisionIdEssential', fieldRevisionIdEssential)
            const localVarPath = `/process/field-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldTypeEssential} fieldTypeEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTypeControllerCreateEntity: async (fieldTypeEssential: FieldTypeEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldTypeEssential' is not null or undefined
            assertParamExists('fieldTypeControllerCreateEntity', 'fieldTypeEssential', fieldTypeEssential)
            const localVarPath = `/process/field-type/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldTypeEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTypeControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldTypeControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/field-type/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTypeControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/field-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTypeControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldTypeControllerGetById', 'id', id)
            const localVarPath = `/process/field-type/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldTypeIdEssential} fieldTypeIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTypeControllerUpdateEntity: async (fieldTypeIdEssential: FieldTypeIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldTypeIdEssential' is not null or undefined
            assertParamExists('fieldTypeControllerUpdateEntity', 'fieldTypeIdEssential', fieldTypeIdEssential)
            const localVarPath = `/process/field-type/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldTypeIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldValueEssential} fieldValueEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueControllerCreateEntity: async (fieldValueEssential: FieldValueEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldValueEssential' is not null or undefined
            assertParamExists('fieldValueControllerCreateEntity', 'fieldValueEssential', fieldValueEssential)
            const localVarPath = `/process/field-value/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldValueEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldValueControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/field-value/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/field-value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldValueControllerGetById', 'id', id)
            const localVarPath = `/process/field-value/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldValueIdEssential} fieldValueIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueControllerUpdateEntity: async (fieldValueIdEssential: FieldValueIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldValueIdEssential' is not null or undefined
            assertParamExists('fieldValueControllerUpdateEntity', 'fieldValueIdEssential', fieldValueIdEssential)
            const localVarPath = `/process/field-value/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldValueIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldValueRevisionEssential} fieldValueRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerCreateEntity: async (fieldValueRevisionEssential: FieldValueRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldValueRevisionEssential' is not null or undefined
            assertParamExists('fieldValueRevisionControllerCreateEntity', 'fieldValueRevisionEssential', fieldValueRevisionEssential)
            const localVarPath = `/process/field-value-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldValueRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldValueRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/field-value-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('fieldValueRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/field-value-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/field-value-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('fieldValueRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/field-value-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldValueRevisionControllerGetById', 'id', id)
            const localVarPath = `/process/field-value-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fieldValueRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/field-value-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FieldValueRevisionIdEssential} fieldValueRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerUpdateEntity: async (fieldValueRevisionIdEssential: FieldValueRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldValueRevisionIdEssential' is not null or undefined
            assertParamExists('fieldValueRevisionControllerUpdateEntity', 'fieldValueRevisionIdEssential', fieldValueRevisionIdEssential)
            const localVarPath = `/process/field-value-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fieldValueRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilterEssential} filterEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerCreateEntity: async (filterEssential: FilterEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterEssential' is not null or undefined
            assertParamExists('filterControllerCreateEntity', 'filterEssential', filterEssential)
            const localVarPath = `/preference/filter/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filterControllerDeleteEntity', 'id', id)
            const localVarPath = `/preference/filter/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/preference/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filterControllerGetById', 'id', id)
            const localVarPath = `/preference/filter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilterIdEssential} filterIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerUpdateEntity: async (filterIdEssential: FilterIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterIdEssential' is not null or undefined
            assertParamExists('filterControllerUpdateEntity', 'filterIdEssential', filterIdEssential)
            const localVarPath = `/preference/filter/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GroupAssignmentEssential} groupAssignmentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAssignmentControllerCreateEntity: async (groupAssignmentEssential: GroupAssignmentEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupAssignmentEssential' is not null or undefined
            assertParamExists('groupAssignmentControllerCreateEntity', 'groupAssignmentEssential', groupAssignmentEssential)
            const localVarPath = `/preference/group-assignment/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupAssignmentEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAssignmentControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('groupAssignmentControllerDeleteEntity', 'id', id)
            const localVarPath = `/preference/group-assignment/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAssignmentControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/preference/group-assignment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAssignmentControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('groupAssignmentControllerGetById', 'id', id)
            const localVarPath = `/preference/group-assignment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GroupAssignmentIdEssential} groupAssignmentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAssignmentControllerUpdateEntity: async (groupAssignmentIdEssential: GroupAssignmentIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupAssignmentIdEssential' is not null or undefined
            assertParamExists('groupAssignmentControllerUpdateEntity', 'groupAssignmentIdEssential', groupAssignmentIdEssential)
            const localVarPath = `/preference/group-assignment/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupAssignmentIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobAidControllerGetFromUrl: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workday/jobaid/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerCreateEntity: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stepControllerCreateEntity', 'body', body)
            const localVarPath = `/process/step/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerGetById', 'id', id)
            const localVarPath = `/process/step/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepIdEssential} stepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerUpdateEntity: async (stepIdEssential: StepIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepIdEssential' is not null or undefined
            assertParamExists('stepControllerUpdateEntity', 'stepIdEssential', stepIdEssential)
            const localVarPath = `/process/step/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepFieldEssential} stepFieldEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerCreateEntity: async (stepFieldEssential: StepFieldEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepFieldEssential' is not null or undefined
            assertParamExists('stepFieldControllerCreateEntity', 'stepFieldEssential', stepFieldEssential)
            const localVarPath = `/process/step-field/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepFieldEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepFieldControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-field/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('stepFieldControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/step-field/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-field`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepFieldControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/step-field/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepFieldControllerGetById', 'id', id)
            const localVarPath = `/process/step-field/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepFieldControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/step-field/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepFieldIdEssential} stepFieldIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerUpdateEntity: async (stepFieldIdEssential: StepFieldIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepFieldIdEssential' is not null or undefined
            assertParamExists('stepFieldControllerUpdateEntity', 'stepFieldIdEssential', stepFieldIdEssential)
            const localVarPath = `/process/step-field/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepFieldIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepKindEssential} stepKindEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepKindControllerCreateEntity: async (stepKindEssential: StepKindEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepKindEssential' is not null or undefined
            assertParamExists('stepKindControllerCreateEntity', 'stepKindEssential', stepKindEssential)
            const localVarPath = `/process/step-kind/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepKindEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepKindControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepKindControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-kind/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepKindControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-kind`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepKindControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepKindControllerGetById', 'id', id)
            const localVarPath = `/process/step-kind/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepKindIdEssential} stepKindIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepKindControllerUpdateEntity: async (stepKindIdEssential: StepKindIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepKindIdEssential' is not null or undefined
            assertParamExists('stepKindControllerUpdateEntity', 'stepKindIdEssential', stepKindIdEssential)
            const localVarPath = `/process/step-kind/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepKindIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepLinkEssential} stepLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerCreateEntity: async (stepLinkEssential: StepLinkEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepLinkEssential' is not null or undefined
            assertParamExists('stepLinkControllerCreateEntity', 'stepLinkEssential', stepLinkEssential)
            const localVarPath = `/process/step-link/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepLinkEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepLinkControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-link/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('stepLinkControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/step-link/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepLinkControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/step-link/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepLinkControllerGetById', 'id', id)
            const localVarPath = `/process/step-link/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepLinkControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/step-link/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepLinkIdEssential} stepLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerUpdateEntity: async (stepLinkIdEssential: StepLinkIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepLinkIdEssential' is not null or undefined
            assertParamExists('stepLinkControllerUpdateEntity', 'stepLinkIdEssential', stepLinkIdEssential)
            const localVarPath = `/process/step-link/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepLinkIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewControllerCreateEntity: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stepReviewControllerCreateEntity', 'body', body)
            const localVarPath = `/process/step-review/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepReviewControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-review/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepReviewControllerGetById', 'id', id)
            const localVarPath = `/process/step-review/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepReviewIdEssential} stepReviewIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewControllerUpdateEntity: async (stepReviewIdEssential: StepReviewIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepReviewIdEssential' is not null or undefined
            assertParamExists('stepReviewControllerUpdateEntity', 'stepReviewIdEssential', stepReviewIdEssential)
            const localVarPath = `/process/step-review/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepReviewIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepReviewLinkEssential} stepReviewLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerCreateEntity: async (stepReviewLinkEssential: StepReviewLinkEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepReviewLinkEssential' is not null or undefined
            assertParamExists('stepReviewLinkControllerCreateEntity', 'stepReviewLinkEssential', stepReviewLinkEssential)
            const localVarPath = `/process/step-review-link/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepReviewLinkEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepReviewLinkControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-review-link/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('stepReviewLinkControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/step-review-link/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-review-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepReviewLinkControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/step-review-link/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepReviewLinkControllerGetById', 'id', id)
            const localVarPath = `/process/step-review-link/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepReviewLinkControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/step-review-link/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepReviewLinkIdEssential} stepReviewLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerUpdateEntity: async (stepReviewLinkIdEssential: StepReviewLinkIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepReviewLinkIdEssential' is not null or undefined
            assertParamExists('stepReviewLinkControllerUpdateEntity', 'stepReviewLinkIdEssential', stepReviewLinkIdEssential)
            const localVarPath = `/process/step-review-link/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepReviewLinkIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepReviewRevisionEssential} stepReviewRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerCreateEntity: async (stepReviewRevisionEssential: StepReviewRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepReviewRevisionEssential' is not null or undefined
            assertParamExists('stepReviewRevisionControllerCreateEntity', 'stepReviewRevisionEssential', stepReviewRevisionEssential)
            const localVarPath = `/process/step-review-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepReviewRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepReviewRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-review-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('stepReviewRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/step-review-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-review-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepReviewRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/step-review-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepReviewRevisionControllerGetById', 'id', id)
            const localVarPath = `/process/step-review-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepReviewRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/step-review-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepReviewRevisionIdEssential} stepReviewRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerUpdateEntity: async (stepReviewRevisionIdEssential: StepReviewRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepReviewRevisionIdEssential' is not null or undefined
            assertParamExists('stepReviewRevisionControllerUpdateEntity', 'stepReviewRevisionIdEssential', stepReviewRevisionIdEssential)
            const localVarPath = `/process/step-review-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepReviewRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepReviewStatusEssential} stepReviewStatusEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewStatusControllerCreateEntity: async (stepReviewStatusEssential: StepReviewStatusEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepReviewStatusEssential' is not null or undefined
            assertParamExists('stepReviewStatusControllerCreateEntity', 'stepReviewStatusEssential', stepReviewStatusEssential)
            const localVarPath = `/process/step-review-status/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepReviewStatusEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewStatusControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepReviewStatusControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-review-status/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewStatusControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-review-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewStatusControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepReviewStatusControllerGetById', 'id', id)
            const localVarPath = `/process/step-review-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepReviewStatusIdEssential} stepReviewStatusIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewStatusControllerUpdateEntity: async (stepReviewStatusIdEssential: StepReviewStatusIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepReviewStatusIdEssential' is not null or undefined
            assertParamExists('stepReviewStatusControllerUpdateEntity', 'stepReviewStatusIdEssential', stepReviewStatusIdEssential)
            const localVarPath = `/process/step-review-status/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepReviewStatusIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepRevisionEssential} stepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateEntity: async (stepRevisionEssential: StepRevisionEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevisionEssential' is not null or undefined
            assertParamExists('stepRevisionControllerCreateEntity', 'stepRevisionEssential', stepRevisionEssential)
            const localVarPath = `/process/step-revision/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevisionEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerDeleteEntity', 'id', id)
            const localVarPath = `/process/step-revision/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindEntity: async (findByColumnQueryUUID: FindByColumnQueryUUID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findByColumnQueryUUID' is not null or undefined
            assertParamExists('stepRevisionControllerFindEntity', 'findByColumnQueryUUID', findByColumnQueryUUID)
            const localVarPath = `/process/step-revision/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findByColumnQueryUUID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/process/step-revision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAllEntities: async (instant: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instant' is not null or undefined
            assertParamExists('stepRevisionControllerGetAllEntities', 'instant', instant)
            const localVarPath = `/process/step-revision/get/all/{instant}`
                .replace(`{${"instant"}}`, encodeURIComponent(String(instant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerGetById', 'id', id)
            const localVarPath = `/process/step-revision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerRemoveEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepRevisionControllerRemoveEntity', 'id', id)
            const localVarPath = `/process/step-revision/remove/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StepRevisionIdEssential} stepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerUpdateEntity: async (stepRevisionIdEssential: StepRevisionIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepRevisionIdEssential' is not null or undefined
            assertParamExists('stepRevisionControllerUpdateEntity', 'stepRevisionIdEssential', stepRevisionIdEssential)
            const localVarPath = `/process/step-revision/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepRevisionIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserGroupEssential} userGroupEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupControllerCreateEntity: async (userGroupEssential: UserGroupEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroupEssential' is not null or undefined
            assertParamExists('userGroupControllerCreateEntity', 'userGroupEssential', userGroupEssential)
            const localVarPath = `/preference/user-group/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGroupEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userGroupControllerDeleteEntity', 'id', id)
            const localVarPath = `/preference/user-group/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/preference/user-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userGroupControllerGetById', 'id', id)
            const localVarPath = `/preference/user-group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserGroupIdEssential} userGroupIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupControllerUpdateEntity: async (userGroupIdEssential: UserGroupIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroupIdEssential' is not null or undefined
            assertParamExists('userGroupControllerUpdateEntity', 'userGroupIdEssential', userGroupIdEssential)
            const localVarPath = `/preference/user-group/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGroupIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerCreateEntity: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('zoneControllerCreateEntity', 'body', body)
            const localVarPath = `/zone/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneControllerDeleteEntity', 'id', id)
            const localVarPath = `/zone/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/zone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetAllPermissionsByUserId: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneControllerGetAllPermissionsByUserId', 'id', id)
            const localVarPath = `/zone/permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneControllerGetById', 'id', id)
            const localVarPath = `/zone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneIdEssential} zoneIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerUpdateEntity: async (zoneIdEssential: ZoneIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneIdEssential' is not null or undefined
            assertParamExists('zoneControllerUpdateEntity', 'zoneIdEssential', zoneIdEssential)
            const localVarPath = `/zone/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneLevelEssential} zoneLevelEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerCreateEntity: async (zoneLevelEssential: ZoneLevelEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneLevelEssential' is not null or undefined
            assertParamExists('zoneLevelControllerCreateEntity', 'zoneLevelEssential', zoneLevelEssential)
            const localVarPath = `/zone/level/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneLevelEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneLevelControllerDeleteEntity', 'id', id)
            const localVarPath = `/zone/level/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/zone/level`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneLevelControllerGetById', 'id', id)
            const localVarPath = `/zone/level/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneLevelIdEssential} zoneLevelIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerUpdateEntity: async (zoneLevelIdEssential: ZoneLevelIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneLevelIdEssential' is not null or undefined
            assertParamExists('zoneLevelControllerUpdateEntity', 'zoneLevelIdEssential', zoneLevelIdEssential)
            const localVarPath = `/zone/level/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneLevelIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneOwnerEssential} zoneOwnerEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerCreateEntity: async (zoneOwnerEssential: ZoneOwnerEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneOwnerEssential' is not null or undefined
            assertParamExists('zoneOwnerControllerCreateEntity', 'zoneOwnerEssential', zoneOwnerEssential)
            const localVarPath = `/zone/owner/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneOwnerEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerDeleteEntity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneOwnerControllerDeleteEntity', 'id', id)
            const localVarPath = `/zone/owner/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerGetAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/zone/owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('zoneOwnerControllerGetById', 'id', id)
            const localVarPath = `/zone/owner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ZoneOwnerIdEssential} zoneOwnerIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerUpdateEntity: async (zoneOwnerIdEssential: ZoneOwnerIdEssential, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneOwnerIdEssential' is not null or undefined
            assertParamExists('zoneOwnerControllerUpdateEntity', 'zoneOwnerIdEssential', zoneOwnerIdEssential)
            const localVarPath = `/zone/owner/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zoneOwnerIdEssential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AggregateControlEssential} aggregateControlEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerCreateAggregate(aggregateControlEssential: AggregateControlEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerCreateAggregate(aggregateControlEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlControllerCreateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerGetAggregate(id, instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlControllerGetAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateControlAll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerGetAllAggregate(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlControllerGetAllAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} step 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerGetAllAggregateByStep(step: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateControlAll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerGetAllAggregateByStep(step, instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlControllerGetAllAggregateByStep']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerRemoveAggregate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlControllerRemoveAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateControlAll} aggregateControlAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlControllerUpdateAggregate(aggregateControlAll: AggregateControlAll, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlControllerUpdateAggregate(aggregateControlAll, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlControllerUpdateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateControlReviewEssential} aggregateControlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential: AggregateControlReviewEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlReviewControllerCreateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlReviewControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlReviewControllerGetAggregate(id, instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlReviewControllerGetAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlReviewControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateControlReviewAll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlReviewControllerGetAllAggregate(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlReviewControllerGetAllAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlReviewControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlReviewControllerRemoveAggregate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlReviewControllerRemoveAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateControlReviewAll} aggregateControlReviewAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll: AggregateControlReviewAll, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateControlReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateControlReviewControllerUpdateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateFieldEssential} aggregateFieldEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateFieldControllerCreateAggregate(aggregateFieldEssential: AggregateFieldEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateFieldAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateFieldControllerCreateAggregate(aggregateFieldEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateFieldControllerCreateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateFieldControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateFieldAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateFieldControllerGetAggregate(id, instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateFieldControllerGetAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateFieldControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateFieldAll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateFieldControllerGetAllAggregate(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateFieldControllerGetAllAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateFieldControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateFieldAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateFieldControllerRemoveAggregate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateFieldControllerRemoveAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateFieldAll} aggregateFieldAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateFieldControllerUpdateAggregate(aggregateFieldAll: AggregateFieldAll, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateFieldAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateFieldControllerUpdateAggregate(aggregateFieldAll, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateFieldControllerUpdateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateFieldValueEssential} aggregateFieldValueEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateFieldValueControllerCreateAggregate(aggregateFieldValueEssential: AggregateFieldValueEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateFieldValueAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateFieldValueControllerCreateAggregate(aggregateFieldValueEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateFieldValueControllerCreateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateFieldValueControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateFieldValueAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateFieldValueControllerGetAggregate(id, instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateFieldValueControllerGetAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateFieldValueControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateFieldValueAll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateFieldValueControllerGetAllAggregate(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateFieldValueControllerGetAllAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateFieldValueControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateFieldValueAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateFieldValueControllerRemoveAggregate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateFieldValueControllerRemoveAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateFieldValueAll} aggregateFieldValueAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateFieldValueControllerUpdateAggregate(aggregateFieldValueAll: AggregateFieldValueAll, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateFieldValueAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateFieldValueControllerUpdateAggregate(aggregateFieldValueAll, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateFieldValueControllerUpdateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateStepEssential} aggregateStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateStepControllerCreateAggregate(aggregateStepEssential: AggregateStepEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateStepAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateStepControllerCreateAggregate(aggregateStepEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateStepControllerCreateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateStepControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateStepAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateStepControllerGetAggregate(id, instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateStepControllerGetAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateStepControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateStepAll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateStepControllerGetAllAggregate(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateStepControllerGetAllAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateStepControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateStepAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateStepControllerRemoveAggregate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateStepControllerRemoveAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateStepAll} aggregateStepAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateStepControllerUpdateAggregate(aggregateStepAll: AggregateStepAll, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateStepAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateStepControllerUpdateAggregate(aggregateStepAll, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateStepControllerUpdateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateStepReviewEssential} aggregateStepReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateStepReviewControllerCreateAggregate(aggregateStepReviewEssential: AggregateStepReviewEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateStepReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateStepReviewControllerCreateAggregate(aggregateStepReviewEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateStepReviewControllerCreateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateStepReviewControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateStepReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateStepReviewControllerGetAggregate(id, instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateStepReviewControllerGetAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateStepReviewControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateStepReviewAll>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateStepReviewControllerGetAllAggregate(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateStepReviewControllerGetAllAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateStepReviewControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateStepReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateStepReviewControllerRemoveAggregate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateStepReviewControllerRemoveAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AggregateStepReviewAll} aggregateStepReviewAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateStepReviewControllerUpdateAggregate(aggregateStepReviewAll: AggregateStepReviewAll, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AggregateStepReviewAll>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateStepReviewControllerUpdateAggregate(aggregateStepReviewAll, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.aggregateStepReviewControllerUpdateAggregate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerCreateEntity(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Control>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlIdEssential} controlIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlControllerUpdateEntity(controlIdEssential: ControlIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlControllerUpdateEntity(controlIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewEssential} controlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewControllerCreateEntity(controlReviewEssential: ControlReviewEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewControllerCreateEntity(controlReviewEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewIdEssential} controlReviewIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewControllerUpdateEntity(controlReviewIdEssential: ControlReviewIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewControllerUpdateEntity(controlReviewIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewDocumentEssential} controlReviewDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential: ControlReviewDocumentEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewDocumentControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewDocumentControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewDocumentControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewDocumentControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReviewDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewDocumentControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewDocumentControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewDocumentControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewDocumentControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewDocumentIdEssential} controlReviewDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential: ControlReviewDocumentIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewDocumentControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewRevisionEssential} controlReviewRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential: ControlReviewRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReviewRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerFindEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReviewRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReviewRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerGetAllEntities(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerGetAllEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerRemoveEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerRemoveEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewRevisionIdEssential} controlReviewRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential: ControlReviewRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewRevisionControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewStatusEssential} controlReviewStatusEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewStatusControllerCreateEntity(controlReviewStatusEssential: ControlReviewStatusEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewStatusControllerCreateEntity(controlReviewStatusEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewStatusControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewStatusControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewStatusControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewStatusControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewStatusControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlReviewStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewStatusControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewStatusControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewStatusControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewStatusControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewStatusControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlReviewStatusIdEssential} controlReviewStatusIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential: ControlReviewStatusIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlReviewStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlReviewStatusControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlRevisionEssential} controlRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerCreateEntity(controlRevisionEssential: ControlRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerCreateEntity(controlRevisionEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlRevisionControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlRevisionControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlRevisionControllerFindEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlRevisionControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerGetAllEntities(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlRevisionControllerGetAllEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlRevisionControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerRemoveEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlRevisionControllerRemoveEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlRevisionControllerUpdateEntity(controlRevisionIdEssential: ControlRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlRevisionControllerUpdateEntity(controlRevisionIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlRevisionControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlStepEssential} controlStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerCreateEntity(controlStepEssential: ControlStepEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerCreateEntity(controlStepEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlStepControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlStepControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerFindEntity(findByColumnQueryUUID, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlStepControllerFindEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlStepControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetAllEntities(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlStepControllerGetAllEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlStepControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerRemoveEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlStepControllerRemoveEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ControlStepIdEssential} controlStepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async controlStepControllerUpdateEntity(controlStepIdEssential: ControlStepIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.controlStepControllerUpdateEntity(controlStepIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.controlStepControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreTasksApiControllerImportAndSaveControls(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreTasksApiControllerImportAndSaveControls(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.coreTasksApiControllerImportAndSaveControls']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} zone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreTasksApiControllerImportAndSaveSteps(zone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AggregateStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreTasksApiControllerImportAndSaveSteps(zone, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.coreTasksApiControllerImportAndSaveSteps']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreTasksApiControllerPing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coreTasksApiControllerPing(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.coreTasksApiControllerPing']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displayDataControllerGetControls(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.displayDataControllerGetControls(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.displayDataControllerGetControls']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displayDataControllerGetStepFields(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.displayDataControllerGetStepFields(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.displayDataControllerGetStepFields']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} to 
         * @param {string} subject 
         * @param {EmailParams} emailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerSendEmail(to: Array<string>, subject: string, emailParams: EmailParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailControllerSendEmail(to, subject, emailParams, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.emailControllerSendEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldEssential} fieldEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldControllerCreateEntity(fieldEssential: FieldEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Field>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldControllerCreateEntity(fieldEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Field>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Field>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldIdEssential} fieldIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldControllerUpdateEntity(fieldIdEssential: FieldIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Field>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldControllerUpdateEntity(fieldIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldGroupEssential} fieldGroupEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldGroupControllerCreateEntity(fieldGroupEssential: FieldGroupEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldGroupControllerCreateEntity(fieldGroupEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldGroupControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldGroupControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldGroupControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldGroupControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldGroupControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldGroupControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldGroupControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldGroupControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldGroupControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldGroupControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldGroupIdEssential} fieldGroupIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldGroupControllerUpdateEntity(fieldGroupIdEssential: FieldGroupIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldGroupControllerUpdateEntity(fieldGroupIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldGroupControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldRevisionEssential} fieldRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldRevisionControllerCreateEntity(fieldRevisionEssential: FieldRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldRevisionControllerCreateEntity(fieldRevisionEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldRevisionControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldRevisionControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldRevisionControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldRevisionControllerFindEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldRevisionControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldRevisionControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldRevisionControllerGetAllEntities(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldRevisionControllerGetAllEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldRevisionControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldRevisionControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldRevisionControllerRemoveEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldRevisionControllerRemoveEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldRevisionIdEssential} fieldRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldRevisionControllerUpdateEntity(fieldRevisionIdEssential: FieldRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldRevisionControllerUpdateEntity(fieldRevisionIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldRevisionControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldTypeEssential} fieldTypeEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldTypeControllerCreateEntity(fieldTypeEssential: FieldTypeEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldTypeControllerCreateEntity(fieldTypeEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldTypeControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldTypeControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldTypeControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldTypeControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldTypeControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldTypeControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldTypeControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldTypeControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldTypeControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldTypeControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldTypeIdEssential} fieldTypeIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldTypeControllerUpdateEntity(fieldTypeIdEssential: FieldTypeIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldTypeControllerUpdateEntity(fieldTypeIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldTypeControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldValueEssential} fieldValueEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueControllerCreateEntity(fieldValueEssential: FieldValueEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueControllerCreateEntity(fieldValueEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldValue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldValueIdEssential} fieldValueIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueControllerUpdateEntity(fieldValueIdEssential: FieldValueIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueControllerUpdateEntity(fieldValueIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldValueRevisionEssential} fieldValueRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueRevisionControllerCreateEntity(fieldValueRevisionEssential: FieldValueRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldValueRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueRevisionControllerCreateEntity(fieldValueRevisionEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueRevisionControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueRevisionControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueRevisionControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldValueRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueRevisionControllerFindEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldValueRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueRevisionControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueRevisionControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldValueRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueRevisionControllerGetAllEntities(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueRevisionControllerGetAllEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldValueRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueRevisionControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueRevisionControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldValueRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueRevisionControllerRemoveEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueRevisionControllerRemoveEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FieldValueRevisionIdEssential} fieldValueRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldValueRevisionControllerUpdateEntity(fieldValueRevisionIdEssential: FieldValueRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldValueRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldValueRevisionControllerUpdateEntity(fieldValueRevisionIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fieldValueRevisionControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FilterEssential} filterEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerCreateEntity(filterEssential: FilterEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Filter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerCreateEntity(filterEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.filterControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.filterControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Filter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.filterControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Filter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.filterControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FilterIdEssential} filterIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterControllerUpdateEntity(filterIdEssential: FilterIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Filter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterControllerUpdateEntity(filterIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.filterControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GroupAssignmentEssential} groupAssignmentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupAssignmentControllerCreateEntity(groupAssignmentEssential: GroupAssignmentEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupAssignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupAssignmentControllerCreateEntity(groupAssignmentEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.groupAssignmentControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupAssignmentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupAssignmentControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.groupAssignmentControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupAssignmentControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupAssignment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupAssignmentControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.groupAssignmentControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupAssignmentControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupAssignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupAssignmentControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.groupAssignmentControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GroupAssignmentIdEssential} groupAssignmentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupAssignmentControllerUpdateEntity(groupAssignmentIdEssential: GroupAssignmentIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupAssignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupAssignmentControllerUpdateEntity(groupAssignmentIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.groupAssignmentControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobAidControllerGetFromUrl(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobAid>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobAidControllerGetFromUrl(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.jobAidControllerGetFromUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerCreateEntity(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Step>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepIdEssential} stepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerUpdateEntity(stepIdEssential: StepIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerUpdateEntity(stepIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepFieldEssential} stepFieldEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepFieldControllerCreateEntity(stepFieldEssential: StepFieldEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepField>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepFieldControllerCreateEntity(stepFieldEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepFieldControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepFieldControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepFieldControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepFieldControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepFieldControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepField>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepFieldControllerFindEntity(findByColumnQueryUUID, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepFieldControllerFindEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepFieldControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepField>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepFieldControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepFieldControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepFieldControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepField>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepFieldControllerGetAllEntities(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepFieldControllerGetAllEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepFieldControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepField>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepFieldControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepFieldControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepFieldControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepField>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepFieldControllerRemoveEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepFieldControllerRemoveEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepFieldIdEssential} stepFieldIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepFieldControllerUpdateEntity(stepFieldIdEssential: StepFieldIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepField>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepFieldControllerUpdateEntity(stepFieldIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepFieldControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepKindEssential} stepKindEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepKindControllerCreateEntity(stepKindEssential: StepKindEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepKind>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepKindControllerCreateEntity(stepKindEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepKindControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepKindControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepKindControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepKindControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepKindControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepKind>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepKindControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepKindControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepKindControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepKind>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepKindControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepKindControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepKindIdEssential} stepKindIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepKindControllerUpdateEntity(stepKindIdEssential: StepKindIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepKind>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepKindControllerUpdateEntity(stepKindIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepKindControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepLinkEssential} stepLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerCreateEntity(stepLinkEssential: StepLinkEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerCreateEntity(stepLinkEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepLinkControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepLinkControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerFindEntity(findByColumnQueryUUID, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepLinkControllerFindEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepLinkControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerGetAllEntities(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepLinkControllerGetAllEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepLinkControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerRemoveEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepLinkControllerRemoveEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepLinkIdEssential} stepLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepLinkControllerUpdateEntity(stepLinkIdEssential: StepLinkIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepLinkControllerUpdateEntity(stepLinkIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepLinkControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewControllerCreateEntity(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepReview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepReviewIdEssential} stepReviewIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewControllerUpdateEntity(stepReviewIdEssential: StepReviewIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewControllerUpdateEntity(stepReviewIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepReviewLinkEssential} stepReviewLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewLinkControllerCreateEntity(stepReviewLinkEssential: StepReviewLinkEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewLinkControllerCreateEntity(stepReviewLinkEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewLinkControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewLinkControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewLinkControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepReviewLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewLinkControllerFindEntity(findByColumnQueryUUID, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewLinkControllerFindEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewLinkControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepReviewLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewLinkControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewLinkControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepReviewLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewLinkControllerGetAllEntities(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewLinkControllerGetAllEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewLinkControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewLinkControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewLinkControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewLinkControllerRemoveEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewLinkControllerRemoveEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepReviewLinkIdEssential} stepReviewLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewLinkControllerUpdateEntity(stepReviewLinkIdEssential: StepReviewLinkIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewLinkControllerUpdateEntity(stepReviewLinkIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewLinkControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepReviewRevisionEssential} stepReviewRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewRevisionControllerCreateEntity(stepReviewRevisionEssential: StepReviewRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewRevisionControllerCreateEntity(stepReviewRevisionEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewRevisionControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewRevisionControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewRevisionControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepReviewRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewRevisionControllerFindEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepReviewRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewRevisionControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewRevisionControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepReviewRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewRevisionControllerGetAllEntities(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewRevisionControllerGetAllEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewRevisionControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewRevisionControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewRevisionControllerRemoveEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewRevisionControllerRemoveEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepReviewRevisionIdEssential} stepReviewRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewRevisionControllerUpdateEntity(stepReviewRevisionIdEssential: StepReviewRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewRevisionControllerUpdateEntity(stepReviewRevisionIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewRevisionControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepReviewStatusEssential} stepReviewStatusEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewStatusControllerCreateEntity(stepReviewStatusEssential: StepReviewStatusEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewStatusControllerCreateEntity(stepReviewStatusEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewStatusControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewStatusControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewStatusControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewStatusControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewStatusControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepReviewStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewStatusControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewStatusControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewStatusControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewStatusControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewStatusControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepReviewStatusIdEssential} stepReviewStatusIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepReviewStatusControllerUpdateEntity(stepReviewStatusIdEssential: StepReviewStatusIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepReviewStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepReviewStatusControllerUpdateEntity(stepReviewStatusIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepReviewStatusControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepRevisionEssential} stepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerCreateEntity(stepRevisionEssential: StepRevisionEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerCreateEntity(stepRevisionEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepRevisionControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepRevisionControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerFindEntity(findByColumnQueryUUID, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepRevisionControllerFindEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepRevisionControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepRevision>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerGetAllEntities(instant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepRevisionControllerGetAllEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepRevisionControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerRemoveEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepRevisionControllerRemoveEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {StepRevisionIdEssential} stepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepRevisionControllerUpdateEntity(stepRevisionIdEssential: StepRevisionIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepRevision>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepRevisionControllerUpdateEntity(stepRevisionIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.stepRevisionControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {UserGroupEssential} userGroupEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupControllerCreateEntity(userGroupEssential: UserGroupEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupControllerCreateEntity(userGroupEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.userGroupControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.userGroupControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.userGroupControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.userGroupControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {UserGroupIdEssential} userGroupIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGroupControllerUpdateEntity(userGroupIdEssential: UserGroupIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGroupControllerUpdateEntity(userGroupIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.userGroupControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerCreateEntity(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Zone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerGetAllPermissionsByUserId(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: object; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerGetAllPermissionsByUserId(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneControllerGetAllPermissionsByUserId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ZoneIdEssential} zoneIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneControllerUpdateEntity(zoneIdEssential: ZoneIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Zone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneControllerUpdateEntity(zoneIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ZoneLevelEssential} zoneLevelEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneLevelControllerCreateEntity(zoneLevelEssential: ZoneLevelEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneLevel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneLevelControllerCreateEntity(zoneLevelEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneLevelControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneLevelControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneLevelControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneLevelControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneLevelControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneLevel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneLevelControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneLevelControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneLevelControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneLevel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneLevelControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneLevelControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ZoneLevelIdEssential} zoneLevelIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneLevelControllerUpdateEntity(zoneLevelIdEssential: ZoneLevelIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneLevel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneLevelControllerUpdateEntity(zoneLevelIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneLevelControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ZoneOwnerEssential} zoneOwnerEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneOwnerControllerCreateEntity(zoneOwnerEssential: ZoneOwnerEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneOwner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneOwnerControllerCreateEntity(zoneOwnerEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneOwnerControllerCreateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneOwnerControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneOwnerControllerDeleteEntity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneOwnerControllerDeleteEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneOwnerControllerGetAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneOwner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneOwnerControllerGetAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneOwnerControllerGetAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneOwnerControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneOwner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneOwnerControllerGetById(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneOwnerControllerGetById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ZoneOwnerIdEssential} zoneOwnerIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential: ZoneOwnerIdEssential, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneOwner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.zoneOwnerControllerUpdateEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {AggregateControlEssential} aggregateControlEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerCreateAggregate(aggregateControlEssential: AggregateControlEssential, options?: RawAxiosRequestConfig): AxiosPromise<AggregateControlAll> {
            return localVarFp.aggregateControlControllerCreateAggregate(aggregateControlEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateControlAll> {
            return localVarFp.aggregateControlControllerGetAggregate(id, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AggregateControlAll>> {
            return localVarFp.aggregateControlControllerGetAllAggregate(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} step 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerGetAllAggregateByStep(step: string, instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AggregateControlAll>> {
            return localVarFp.aggregateControlControllerGetAllAggregateByStep(step, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateControlAll> {
            return localVarFp.aggregateControlControllerRemoveAggregate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateControlAll} aggregateControlAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlControllerUpdateAggregate(aggregateControlAll: AggregateControlAll, options?: RawAxiosRequestConfig): AxiosPromise<AggregateControlAll> {
            return localVarFp.aggregateControlControllerUpdateAggregate(aggregateControlAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateControlReviewEssential} aggregateControlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential: AggregateControlReviewEssential, options?: RawAxiosRequestConfig): AxiosPromise<AggregateControlReviewAll> {
            return localVarFp.aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateControlReviewAll> {
            return localVarFp.aggregateControlReviewControllerGetAggregate(id, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AggregateControlReviewAll>> {
            return localVarFp.aggregateControlReviewControllerGetAllAggregate(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateControlReviewAll> {
            return localVarFp.aggregateControlReviewControllerRemoveAggregate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateControlReviewAll} aggregateControlReviewAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll: AggregateControlReviewAll, options?: RawAxiosRequestConfig): AxiosPromise<AggregateControlReviewAll> {
            return localVarFp.aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateFieldEssential} aggregateFieldEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldControllerCreateAggregate(aggregateFieldEssential: AggregateFieldEssential, options?: RawAxiosRequestConfig): AxiosPromise<AggregateFieldAll> {
            return localVarFp.aggregateFieldControllerCreateAggregate(aggregateFieldEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateFieldAll> {
            return localVarFp.aggregateFieldControllerGetAggregate(id, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AggregateFieldAll>> {
            return localVarFp.aggregateFieldControllerGetAllAggregate(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateFieldAll> {
            return localVarFp.aggregateFieldControllerRemoveAggregate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateFieldAll} aggregateFieldAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldControllerUpdateAggregate(aggregateFieldAll: AggregateFieldAll, options?: RawAxiosRequestConfig): AxiosPromise<AggregateFieldAll> {
            return localVarFp.aggregateFieldControllerUpdateAggregate(aggregateFieldAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateFieldValueEssential} aggregateFieldValueEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldValueControllerCreateAggregate(aggregateFieldValueEssential: AggregateFieldValueEssential, options?: RawAxiosRequestConfig): AxiosPromise<AggregateFieldValueAll> {
            return localVarFp.aggregateFieldValueControllerCreateAggregate(aggregateFieldValueEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldValueControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateFieldValueAll> {
            return localVarFp.aggregateFieldValueControllerGetAggregate(id, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldValueControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AggregateFieldValueAll>> {
            return localVarFp.aggregateFieldValueControllerGetAllAggregate(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldValueControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateFieldValueAll> {
            return localVarFp.aggregateFieldValueControllerRemoveAggregate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateFieldValueAll} aggregateFieldValueAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateFieldValueControllerUpdateAggregate(aggregateFieldValueAll: AggregateFieldValueAll, options?: RawAxiosRequestConfig): AxiosPromise<AggregateFieldValueAll> {
            return localVarFp.aggregateFieldValueControllerUpdateAggregate(aggregateFieldValueAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateStepEssential} aggregateStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepControllerCreateAggregate(aggregateStepEssential: AggregateStepEssential, options?: RawAxiosRequestConfig): AxiosPromise<AggregateStepAll> {
            return localVarFp.aggregateStepControllerCreateAggregate(aggregateStepEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateStepAll> {
            return localVarFp.aggregateStepControllerGetAggregate(id, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AggregateStepAll>> {
            return localVarFp.aggregateStepControllerGetAllAggregate(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateStepAll> {
            return localVarFp.aggregateStepControllerRemoveAggregate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateStepAll} aggregateStepAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepControllerUpdateAggregate(aggregateStepAll: AggregateStepAll, options?: RawAxiosRequestConfig): AxiosPromise<AggregateStepAll> {
            return localVarFp.aggregateStepControllerUpdateAggregate(aggregateStepAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateStepReviewEssential} aggregateStepReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepReviewControllerCreateAggregate(aggregateStepReviewEssential: AggregateStepReviewEssential, options?: RawAxiosRequestConfig): AxiosPromise<AggregateStepReviewAll> {
            return localVarFp.aggregateStepReviewControllerCreateAggregate(aggregateStepReviewEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepReviewControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateStepReviewAll> {
            return localVarFp.aggregateStepReviewControllerGetAggregate(id, instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepReviewControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AggregateStepReviewAll>> {
            return localVarFp.aggregateStepReviewControllerGetAllAggregate(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepReviewControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<AggregateStepReviewAll> {
            return localVarFp.aggregateStepReviewControllerRemoveAggregate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregateStepReviewAll} aggregateStepReviewAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateStepReviewControllerUpdateAggregate(aggregateStepReviewAll: AggregateStepReviewAll, options?: RawAxiosRequestConfig): AxiosPromise<AggregateStepReviewAll> {
            return localVarFp.aggregateStepReviewControllerUpdateAggregate(aggregateStepReviewAll, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): AxiosPromise<Control> {
            return localVarFp.controlControllerCreateEntity(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<Control>> {
            return localVarFp.controlControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Control> {
            return localVarFp.controlControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlIdEssential} controlIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlControllerUpdateEntity(controlIdEssential: ControlIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<Control> {
            return localVarFp.controlControllerUpdateEntity(controlIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewEssential} controlReviewEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerCreateEntity(controlReviewEssential: ControlReviewEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlReview> {
            return localVarFp.controlReviewControllerCreateEntity(controlReviewEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlReviewControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlReview>> {
            return localVarFp.controlReviewControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlReview> {
            return localVarFp.controlReviewControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewIdEssential} controlReviewIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewControllerUpdateEntity(controlReviewIdEssential: ControlReviewIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlReview> {
            return localVarFp.controlReviewControllerUpdateEntity(controlReviewIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewDocumentEssential} controlReviewDocumentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential: ControlReviewDocumentEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlReviewDocument> {
            return localVarFp.controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlReviewDocumentControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlReviewDocument>> {
            return localVarFp.controlReviewDocumentControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlReviewDocument> {
            return localVarFp.controlReviewDocumentControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewDocumentIdEssential} controlReviewDocumentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential: ControlReviewDocumentIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlReviewDocument> {
            return localVarFp.controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewRevisionEssential} controlReviewRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential: ControlReviewRevisionEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlReviewRevision> {
            return localVarFp.controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlReviewRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlReviewRevision>> {
            return localVarFp.controlReviewRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlReviewRevision>> {
            return localVarFp.controlReviewRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlReviewRevision>> {
            return localVarFp.controlReviewRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlReviewRevision> {
            return localVarFp.controlReviewRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlReviewRevision> {
            return localVarFp.controlReviewRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewRevisionIdEssential} controlReviewRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential: ControlReviewRevisionIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlReviewRevision> {
            return localVarFp.controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewStatusEssential} controlReviewStatusEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerCreateEntity(controlReviewStatusEssential: ControlReviewStatusEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlReviewStatus> {
            return localVarFp.controlReviewStatusControllerCreateEntity(controlReviewStatusEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlReviewStatusControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlReviewStatus>> {
            return localVarFp.controlReviewStatusControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlReviewStatus> {
            return localVarFp.controlReviewStatusControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlReviewStatusIdEssential} controlReviewStatusIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential: ControlReviewStatusIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlReviewStatus> {
            return localVarFp.controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlRevisionEssential} controlRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerCreateEntity(controlRevisionEssential: ControlRevisionEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerCreateEntity(controlRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlRevision>> {
            return localVarFp.controlRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlRevision>> {
            return localVarFp.controlRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlRevision>> {
            return localVarFp.controlRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlRevisionControllerUpdateEntity(controlRevisionIdEssential: ControlRevisionIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlRevision> {
            return localVarFp.controlRevisionControllerUpdateEntity(controlRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlStepEssential} controlStepEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerCreateEntity(controlStepEssential: ControlStepEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerCreateEntity(controlStepEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.controlStepControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlStep>> {
            return localVarFp.controlStepControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ControlStepIdEssential} controlStepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        controlStepControllerUpdateEntity(controlStepIdEssential: ControlStepIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ControlStep> {
            return localVarFp.controlStepControllerUpdateEntity(controlStepIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveControls(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.coreTasksApiControllerImportAndSaveControls(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} zone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerImportAndSaveSteps(zone: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AggregateStep>> {
            return localVarFp.coreTasksApiControllerImportAndSaveSteps(zone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreTasksApiControllerPing(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.coreTasksApiControllerPing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayDataControllerGetControls(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.displayDataControllerGetControls(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayDataControllerGetStepFields(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.displayDataControllerGetStepFields(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} to 
         * @param {string} subject 
         * @param {EmailParams} emailParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSendEmail(to: Array<string>, subject: string, emailParams: EmailParams, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.emailControllerSendEmail(to, subject, emailParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldEssential} fieldEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerCreateEntity(fieldEssential: FieldEssential, options?: RawAxiosRequestConfig): AxiosPromise<Field> {
            return localVarFp.fieldControllerCreateEntity(fieldEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.fieldControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<Field>> {
            return localVarFp.fieldControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Field> {
            return localVarFp.fieldControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldIdEssential} fieldIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerUpdateEntity(fieldIdEssential: FieldIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<Field> {
            return localVarFp.fieldControllerUpdateEntity(fieldIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldGroupEssential} fieldGroupEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGroupControllerCreateEntity(fieldGroupEssential: FieldGroupEssential, options?: RawAxiosRequestConfig): AxiosPromise<FieldGroup> {
            return localVarFp.fieldGroupControllerCreateEntity(fieldGroupEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGroupControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.fieldGroupControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGroupControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<FieldGroup>> {
            return localVarFp.fieldGroupControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGroupControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FieldGroup> {
            return localVarFp.fieldGroupControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldGroupIdEssential} fieldGroupIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldGroupControllerUpdateEntity(fieldGroupIdEssential: FieldGroupIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<FieldGroup> {
            return localVarFp.fieldGroupControllerUpdateEntity(fieldGroupIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldRevisionEssential} fieldRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerCreateEntity(fieldRevisionEssential: FieldRevisionEssential, options?: RawAxiosRequestConfig): AxiosPromise<FieldRevision> {
            return localVarFp.fieldRevisionControllerCreateEntity(fieldRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.fieldRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<FieldRevision>> {
            return localVarFp.fieldRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<FieldRevision>> {
            return localVarFp.fieldRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<FieldRevision>> {
            return localVarFp.fieldRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FieldRevision> {
            return localVarFp.fieldRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FieldRevision> {
            return localVarFp.fieldRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldRevisionIdEssential} fieldRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldRevisionControllerUpdateEntity(fieldRevisionIdEssential: FieldRevisionIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<FieldRevision> {
            return localVarFp.fieldRevisionControllerUpdateEntity(fieldRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldTypeEssential} fieldTypeEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTypeControllerCreateEntity(fieldTypeEssential: FieldTypeEssential, options?: RawAxiosRequestConfig): AxiosPromise<FieldType> {
            return localVarFp.fieldTypeControllerCreateEntity(fieldTypeEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTypeControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.fieldTypeControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTypeControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<FieldType>> {
            return localVarFp.fieldTypeControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTypeControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FieldType> {
            return localVarFp.fieldTypeControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldTypeIdEssential} fieldTypeIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTypeControllerUpdateEntity(fieldTypeIdEssential: FieldTypeIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<FieldType> {
            return localVarFp.fieldTypeControllerUpdateEntity(fieldTypeIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldValueEssential} fieldValueEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueControllerCreateEntity(fieldValueEssential: FieldValueEssential, options?: RawAxiosRequestConfig): AxiosPromise<FieldValue> {
            return localVarFp.fieldValueControllerCreateEntity(fieldValueEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.fieldValueControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<FieldValue>> {
            return localVarFp.fieldValueControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FieldValue> {
            return localVarFp.fieldValueControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldValueIdEssential} fieldValueIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueControllerUpdateEntity(fieldValueIdEssential: FieldValueIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<FieldValue> {
            return localVarFp.fieldValueControllerUpdateEntity(fieldValueIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldValueRevisionEssential} fieldValueRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerCreateEntity(fieldValueRevisionEssential: FieldValueRevisionEssential, options?: RawAxiosRequestConfig): AxiosPromise<FieldValueRevision> {
            return localVarFp.fieldValueRevisionControllerCreateEntity(fieldValueRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.fieldValueRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<FieldValueRevision>> {
            return localVarFp.fieldValueRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<FieldValueRevision>> {
            return localVarFp.fieldValueRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<FieldValueRevision>> {
            return localVarFp.fieldValueRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FieldValueRevision> {
            return localVarFp.fieldValueRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<FieldValueRevision> {
            return localVarFp.fieldValueRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FieldValueRevisionIdEssential} fieldValueRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldValueRevisionControllerUpdateEntity(fieldValueRevisionIdEssential: FieldValueRevisionIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<FieldValueRevision> {
            return localVarFp.fieldValueRevisionControllerUpdateEntity(fieldValueRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilterEssential} filterEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerCreateEntity(filterEssential: FilterEssential, options?: RawAxiosRequestConfig): AxiosPromise<Filter> {
            return localVarFp.filterControllerCreateEntity(filterEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.filterControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<Filter>> {
            return localVarFp.filterControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Filter> {
            return localVarFp.filterControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilterIdEssential} filterIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterControllerUpdateEntity(filterIdEssential: FilterIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<Filter> {
            return localVarFp.filterControllerUpdateEntity(filterIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GroupAssignmentEssential} groupAssignmentEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAssignmentControllerCreateEntity(groupAssignmentEssential: GroupAssignmentEssential, options?: RawAxiosRequestConfig): AxiosPromise<GroupAssignment> {
            return localVarFp.groupAssignmentControllerCreateEntity(groupAssignmentEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAssignmentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.groupAssignmentControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAssignmentControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<GroupAssignment>> {
            return localVarFp.groupAssignmentControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAssignmentControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GroupAssignment> {
            return localVarFp.groupAssignmentControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GroupAssignmentIdEssential} groupAssignmentIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupAssignmentControllerUpdateEntity(groupAssignmentIdEssential: GroupAssignmentIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<GroupAssignment> {
            return localVarFp.groupAssignmentControllerUpdateEntity(groupAssignmentIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobAidControllerGetFromUrl(options?: RawAxiosRequestConfig): AxiosPromise<Array<JobAid>> {
            return localVarFp.jobAidControllerGetFromUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): AxiosPromise<Step> {
            return localVarFp.stepControllerCreateEntity(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<Step>> {
            return localVarFp.stepControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Step> {
            return localVarFp.stepControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepIdEssential} stepIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerUpdateEntity(stepIdEssential: StepIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<Step> {
            return localVarFp.stepControllerUpdateEntity(stepIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepFieldEssential} stepFieldEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerCreateEntity(stepFieldEssential: StepFieldEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepField> {
            return localVarFp.stepFieldControllerCreateEntity(stepFieldEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepFieldControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepField>> {
            return localVarFp.stepFieldControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepField>> {
            return localVarFp.stepFieldControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepField>> {
            return localVarFp.stepFieldControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepField> {
            return localVarFp.stepFieldControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepField> {
            return localVarFp.stepFieldControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepFieldIdEssential} stepFieldIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFieldControllerUpdateEntity(stepFieldIdEssential: StepFieldIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepField> {
            return localVarFp.stepFieldControllerUpdateEntity(stepFieldIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepKindEssential} stepKindEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepKindControllerCreateEntity(stepKindEssential: StepKindEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepKind> {
            return localVarFp.stepKindControllerCreateEntity(stepKindEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepKindControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepKindControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepKindControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepKind>> {
            return localVarFp.stepKindControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepKindControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepKind> {
            return localVarFp.stepKindControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepKindIdEssential} stepKindIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepKindControllerUpdateEntity(stepKindIdEssential: StepKindIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepKind> {
            return localVarFp.stepKindControllerUpdateEntity(stepKindIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepLinkEssential} stepLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerCreateEntity(stepLinkEssential: StepLinkEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerCreateEntity(stepLinkEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepLinkControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepLink>> {
            return localVarFp.stepLinkControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepLinkIdEssential} stepLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepLinkControllerUpdateEntity(stepLinkIdEssential: StepLinkIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepLink> {
            return localVarFp.stepLinkControllerUpdateEntity(stepLinkIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): AxiosPromise<StepReview> {
            return localVarFp.stepReviewControllerCreateEntity(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepReviewControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepReview>> {
            return localVarFp.stepReviewControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepReview> {
            return localVarFp.stepReviewControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepReviewIdEssential} stepReviewIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewControllerUpdateEntity(stepReviewIdEssential: StepReviewIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepReview> {
            return localVarFp.stepReviewControllerUpdateEntity(stepReviewIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepReviewLinkEssential} stepReviewLinkEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerCreateEntity(stepReviewLinkEssential: StepReviewLinkEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewLink> {
            return localVarFp.stepReviewLinkControllerCreateEntity(stepReviewLinkEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepReviewLinkControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepReviewLink>> {
            return localVarFp.stepReviewLinkControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepReviewLink>> {
            return localVarFp.stepReviewLinkControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepReviewLink>> {
            return localVarFp.stepReviewLinkControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewLink> {
            return localVarFp.stepReviewLinkControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewLink> {
            return localVarFp.stepReviewLinkControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepReviewLinkIdEssential} stepReviewLinkIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewLinkControllerUpdateEntity(stepReviewLinkIdEssential: StepReviewLinkIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewLink> {
            return localVarFp.stepReviewLinkControllerUpdateEntity(stepReviewLinkIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepReviewRevisionEssential} stepReviewRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerCreateEntity(stepReviewRevisionEssential: StepReviewRevisionEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewRevision> {
            return localVarFp.stepReviewRevisionControllerCreateEntity(stepReviewRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepReviewRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepReviewRevision>> {
            return localVarFp.stepReviewRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepReviewRevision>> {
            return localVarFp.stepReviewRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepReviewRevision>> {
            return localVarFp.stepReviewRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewRevision> {
            return localVarFp.stepReviewRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewRevision> {
            return localVarFp.stepReviewRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepReviewRevisionIdEssential} stepReviewRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewRevisionControllerUpdateEntity(stepReviewRevisionIdEssential: StepReviewRevisionIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewRevision> {
            return localVarFp.stepReviewRevisionControllerUpdateEntity(stepReviewRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepReviewStatusEssential} stepReviewStatusEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewStatusControllerCreateEntity(stepReviewStatusEssential: StepReviewStatusEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewStatus> {
            return localVarFp.stepReviewStatusControllerCreateEntity(stepReviewStatusEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewStatusControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepReviewStatusControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewStatusControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepReviewStatus>> {
            return localVarFp.stepReviewStatusControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewStatusControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewStatus> {
            return localVarFp.stepReviewStatusControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepReviewStatusIdEssential} stepReviewStatusIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepReviewStatusControllerUpdateEntity(stepReviewStatusIdEssential: StepReviewStatusIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepReviewStatus> {
            return localVarFp.stepReviewStatusControllerUpdateEntity(stepReviewStatusIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepRevisionEssential} stepRevisionEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerCreateEntity(stepRevisionEssential: StepRevisionEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerCreateEntity(stepRevisionEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.stepRevisionControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<StepRevision>> {
            return localVarFp.stepRevisionControllerGetAllEntities(instant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerRemoveEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StepRevisionIdEssential} stepRevisionIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepRevisionControllerUpdateEntity(stepRevisionIdEssential: StepRevisionIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<StepRevision> {
            return localVarFp.stepRevisionControllerUpdateEntity(stepRevisionIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserGroupEssential} userGroupEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupControllerCreateEntity(userGroupEssential: UserGroupEssential, options?: RawAxiosRequestConfig): AxiosPromise<UserGroup> {
            return localVarFp.userGroupControllerCreateEntity(userGroupEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.userGroupControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserGroup>> {
            return localVarFp.userGroupControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<UserGroup> {
            return localVarFp.userGroupControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserGroupIdEssential} userGroupIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGroupControllerUpdateEntity(userGroupIdEssential: UserGroupIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<UserGroup> {
            return localVarFp.userGroupControllerUpdateEntity(userGroupIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerCreateEntity(body: object, options?: RawAxiosRequestConfig): AxiosPromise<Zone> {
            return localVarFp.zoneControllerCreateEntity(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.zoneControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<Zone>> {
            return localVarFp.zoneControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetAllPermissionsByUserId(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<{ [key: string]: object; }>> {
            return localVarFp.zoneControllerGetAllPermissionsByUserId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Zone> {
            return localVarFp.zoneControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneIdEssential} zoneIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneControllerUpdateEntity(zoneIdEssential: ZoneIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<Zone> {
            return localVarFp.zoneControllerUpdateEntity(zoneIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneLevelEssential} zoneLevelEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerCreateEntity(zoneLevelEssential: ZoneLevelEssential, options?: RawAxiosRequestConfig): AxiosPromise<ZoneLevel> {
            return localVarFp.zoneLevelControllerCreateEntity(zoneLevelEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.zoneLevelControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ZoneLevel>> {
            return localVarFp.zoneLevelControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ZoneLevel> {
            return localVarFp.zoneLevelControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneLevelIdEssential} zoneLevelIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneLevelControllerUpdateEntity(zoneLevelIdEssential: ZoneLevelIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ZoneLevel> {
            return localVarFp.zoneLevelControllerUpdateEntity(zoneLevelIdEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneOwnerEssential} zoneOwnerEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerCreateEntity(zoneOwnerEssential: ZoneOwnerEssential, options?: RawAxiosRequestConfig): AxiosPromise<ZoneOwner> {
            return localVarFp.zoneOwnerControllerCreateEntity(zoneOwnerEssential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.zoneOwnerControllerDeleteEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerGetAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<ZoneOwner>> {
            return localVarFp.zoneOwnerControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ZoneOwner> {
            return localVarFp.zoneOwnerControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoneOwnerIdEssential} zoneOwnerIdEssential 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential: ZoneOwnerIdEssential, options?: RawAxiosRequestConfig): AxiosPromise<ZoneOwner> {
            return localVarFp.zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {AggregateControlEssential} aggregateControlEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerCreateAggregate(aggregateControlEssential: AggregateControlEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerCreateAggregate(aggregateControlEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerGetAggregate(id, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerGetAllAggregate(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} step 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerGetAllAggregateByStep(step: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerGetAllAggregateByStep(step, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerRemoveAggregate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateControlAll} aggregateControlAll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlControllerUpdateAggregate(aggregateControlAll: AggregateControlAll, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlControllerUpdateAggregate(aggregateControlAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateControlReviewEssential} aggregateControlReviewEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential: AggregateControlReviewEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlReviewControllerCreateAggregate(aggregateControlReviewEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlReviewControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlReviewControllerGetAggregate(id, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlReviewControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlReviewControllerGetAllAggregate(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlReviewControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlReviewControllerRemoveAggregate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateControlReviewAll} aggregateControlReviewAll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll: AggregateControlReviewAll, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateControlReviewControllerUpdateAggregate(aggregateControlReviewAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateFieldEssential} aggregateFieldEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateFieldControllerCreateAggregate(aggregateFieldEssential: AggregateFieldEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateFieldControllerCreateAggregate(aggregateFieldEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateFieldControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateFieldControllerGetAggregate(id, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateFieldControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateFieldControllerGetAllAggregate(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateFieldControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateFieldControllerRemoveAggregate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateFieldAll} aggregateFieldAll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateFieldControllerUpdateAggregate(aggregateFieldAll: AggregateFieldAll, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateFieldControllerUpdateAggregate(aggregateFieldAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateFieldValueEssential} aggregateFieldValueEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateFieldValueControllerCreateAggregate(aggregateFieldValueEssential: AggregateFieldValueEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateFieldValueControllerCreateAggregate(aggregateFieldValueEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateFieldValueControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateFieldValueControllerGetAggregate(id, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateFieldValueControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateFieldValueControllerGetAllAggregate(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateFieldValueControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateFieldValueControllerRemoveAggregate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateFieldValueAll} aggregateFieldValueAll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateFieldValueControllerUpdateAggregate(aggregateFieldValueAll: AggregateFieldValueAll, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateFieldValueControllerUpdateAggregate(aggregateFieldValueAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateStepEssential} aggregateStepEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateStepControllerCreateAggregate(aggregateStepEssential: AggregateStepEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateStepControllerCreateAggregate(aggregateStepEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateStepControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateStepControllerGetAggregate(id, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateStepControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateStepControllerGetAllAggregate(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateStepControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateStepControllerRemoveAggregate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateStepAll} aggregateStepAll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateStepControllerUpdateAggregate(aggregateStepAll: AggregateStepAll, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateStepControllerUpdateAggregate(aggregateStepAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateStepReviewEssential} aggregateStepReviewEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateStepReviewControllerCreateAggregate(aggregateStepReviewEssential: AggregateStepReviewEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateStepReviewControllerCreateAggregate(aggregateStepReviewEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateStepReviewControllerGetAggregate(id: string, instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateStepReviewControllerGetAggregate(id, instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateStepReviewControllerGetAllAggregate(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateStepReviewControllerGetAllAggregate(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateStepReviewControllerRemoveAggregate(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateStepReviewControllerRemoveAggregate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregateStepReviewAll} aggregateStepReviewAll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aggregateStepReviewControllerUpdateAggregate(aggregateStepReviewAll: AggregateStepReviewAll, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aggregateStepReviewControllerUpdateAggregate(aggregateStepReviewAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerCreateEntity(body: object, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerCreateEntity(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlIdEssential} controlIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlControllerUpdateEntity(controlIdEssential: ControlIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlControllerUpdateEntity(controlIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewEssential} controlReviewEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewControllerCreateEntity(controlReviewEssential: ControlReviewEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewControllerCreateEntity(controlReviewEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewIdEssential} controlReviewIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewControllerUpdateEntity(controlReviewIdEssential: ControlReviewIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewControllerUpdateEntity(controlReviewIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewDocumentEssential} controlReviewDocumentEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential: ControlReviewDocumentEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewDocumentControllerCreateEntity(controlReviewDocumentEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewDocumentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewDocumentControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewDocumentControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewDocumentControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewDocumentControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewDocumentControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewDocumentIdEssential} controlReviewDocumentIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential: ControlReviewDocumentIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewDocumentControllerUpdateEntity(controlReviewDocumentIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewRevisionEssential} controlReviewRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential: ControlReviewRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerCreateEntity(controlReviewRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewRevisionIdEssential} controlReviewRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential: ControlReviewRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewRevisionControllerUpdateEntity(controlReviewRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewStatusEssential} controlReviewStatusEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewStatusControllerCreateEntity(controlReviewStatusEssential: ControlReviewStatusEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewStatusControllerCreateEntity(controlReviewStatusEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewStatusControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewStatusControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewStatusControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewStatusControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewStatusControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewStatusControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlReviewStatusIdEssential} controlReviewStatusIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential: ControlReviewStatusIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlReviewStatusControllerUpdateEntity(controlReviewStatusIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlRevisionEssential} controlRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerCreateEntity(controlRevisionEssential: ControlRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerCreateEntity(controlRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlRevisionIdEssential} controlRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlRevisionControllerUpdateEntity(controlRevisionIdEssential: ControlRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlRevisionControllerUpdateEntity(controlRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlStepEssential} controlStepEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerCreateEntity(controlStepEssential: ControlStepEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerCreateEntity(controlStepEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ControlStepIdEssential} controlStepIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public controlStepControllerUpdateEntity(controlStepIdEssential: ControlStepIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).controlStepControllerUpdateEntity(controlStepIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coreTasksApiControllerImportAndSaveControls(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coreTasksApiControllerImportAndSaveControls(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} zone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coreTasksApiControllerImportAndSaveSteps(zone: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coreTasksApiControllerImportAndSaveSteps(zone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coreTasksApiControllerPing(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coreTasksApiControllerPing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public displayDataControllerGetControls(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).displayDataControllerGetControls(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public displayDataControllerGetStepFields(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).displayDataControllerGetStepFields(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} to 
     * @param {string} subject 
     * @param {EmailParams} emailParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public emailControllerSendEmail(to: Array<string>, subject: string, emailParams: EmailParams, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).emailControllerSendEmail(to, subject, emailParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldEssential} fieldEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldControllerCreateEntity(fieldEssential: FieldEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldControllerCreateEntity(fieldEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldIdEssential} fieldIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldControllerUpdateEntity(fieldIdEssential: FieldIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldControllerUpdateEntity(fieldIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldGroupEssential} fieldGroupEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldGroupControllerCreateEntity(fieldGroupEssential: FieldGroupEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldGroupControllerCreateEntity(fieldGroupEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldGroupControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldGroupControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldGroupControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldGroupControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldGroupControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldGroupControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldGroupIdEssential} fieldGroupIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldGroupControllerUpdateEntity(fieldGroupIdEssential: FieldGroupIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldGroupControllerUpdateEntity(fieldGroupIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldRevisionEssential} fieldRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldRevisionControllerCreateEntity(fieldRevisionEssential: FieldRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldRevisionControllerCreateEntity(fieldRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldRevisionIdEssential} fieldRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldRevisionControllerUpdateEntity(fieldRevisionIdEssential: FieldRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldRevisionControllerUpdateEntity(fieldRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldTypeEssential} fieldTypeEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldTypeControllerCreateEntity(fieldTypeEssential: FieldTypeEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldTypeControllerCreateEntity(fieldTypeEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldTypeControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldTypeControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldTypeControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldTypeControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldTypeControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldTypeControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldTypeIdEssential} fieldTypeIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldTypeControllerUpdateEntity(fieldTypeIdEssential: FieldTypeIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldTypeControllerUpdateEntity(fieldTypeIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldValueEssential} fieldValueEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueControllerCreateEntity(fieldValueEssential: FieldValueEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueControllerCreateEntity(fieldValueEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldValueIdEssential} fieldValueIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueControllerUpdateEntity(fieldValueIdEssential: FieldValueIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueControllerUpdateEntity(fieldValueIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldValueRevisionEssential} fieldValueRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueRevisionControllerCreateEntity(fieldValueRevisionEssential: FieldValueRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueRevisionControllerCreateEntity(fieldValueRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FieldValueRevisionIdEssential} fieldValueRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fieldValueRevisionControllerUpdateEntity(fieldValueRevisionIdEssential: FieldValueRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fieldValueRevisionControllerUpdateEntity(fieldValueRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilterEssential} filterEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerCreateEntity(filterEssential: FilterEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerCreateEntity(filterEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilterIdEssential} filterIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public filterControllerUpdateEntity(filterIdEssential: FilterIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).filterControllerUpdateEntity(filterIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GroupAssignmentEssential} groupAssignmentEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public groupAssignmentControllerCreateEntity(groupAssignmentEssential: GroupAssignmentEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).groupAssignmentControllerCreateEntity(groupAssignmentEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public groupAssignmentControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).groupAssignmentControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public groupAssignmentControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).groupAssignmentControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public groupAssignmentControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).groupAssignmentControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GroupAssignmentIdEssential} groupAssignmentIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public groupAssignmentControllerUpdateEntity(groupAssignmentIdEssential: GroupAssignmentIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).groupAssignmentControllerUpdateEntity(groupAssignmentIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public jobAidControllerGetFromUrl(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).jobAidControllerGetFromUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerCreateEntity(body: object, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerCreateEntity(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepIdEssential} stepIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepControllerUpdateEntity(stepIdEssential: StepIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepControllerUpdateEntity(stepIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepFieldEssential} stepFieldEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepFieldControllerCreateEntity(stepFieldEssential: StepFieldEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepFieldControllerCreateEntity(stepFieldEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepFieldControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepFieldControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepFieldControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepFieldControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepFieldControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepFieldControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepFieldControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepFieldControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepFieldControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepFieldControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepFieldControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepFieldControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepFieldIdEssential} stepFieldIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepFieldControllerUpdateEntity(stepFieldIdEssential: StepFieldIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepFieldControllerUpdateEntity(stepFieldIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepKindEssential} stepKindEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepKindControllerCreateEntity(stepKindEssential: StepKindEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepKindControllerCreateEntity(stepKindEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepKindControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepKindControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepKindControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepKindControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepKindControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepKindControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepKindIdEssential} stepKindIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepKindControllerUpdateEntity(stepKindIdEssential: StepKindIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepKindControllerUpdateEntity(stepKindIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepLinkEssential} stepLinkEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerCreateEntity(stepLinkEssential: StepLinkEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerCreateEntity(stepLinkEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepLinkIdEssential} stepLinkIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepLinkControllerUpdateEntity(stepLinkIdEssential: StepLinkIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepLinkControllerUpdateEntity(stepLinkIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewControllerCreateEntity(body: object, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewControllerCreateEntity(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepReviewIdEssential} stepReviewIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewControllerUpdateEntity(stepReviewIdEssential: StepReviewIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewControllerUpdateEntity(stepReviewIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepReviewLinkEssential} stepReviewLinkEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewLinkControllerCreateEntity(stepReviewLinkEssential: StepReviewLinkEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewLinkControllerCreateEntity(stepReviewLinkEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewLinkControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewLinkControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewLinkControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewLinkControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewLinkControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewLinkControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewLinkControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewLinkControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewLinkControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewLinkControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewLinkControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewLinkControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepReviewLinkIdEssential} stepReviewLinkIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewLinkControllerUpdateEntity(stepReviewLinkIdEssential: StepReviewLinkIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewLinkControllerUpdateEntity(stepReviewLinkIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepReviewRevisionEssential} stepReviewRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewRevisionControllerCreateEntity(stepReviewRevisionEssential: StepReviewRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewRevisionControllerCreateEntity(stepReviewRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepReviewRevisionIdEssential} stepReviewRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewRevisionControllerUpdateEntity(stepReviewRevisionIdEssential: StepReviewRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewRevisionControllerUpdateEntity(stepReviewRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepReviewStatusEssential} stepReviewStatusEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewStatusControllerCreateEntity(stepReviewStatusEssential: StepReviewStatusEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewStatusControllerCreateEntity(stepReviewStatusEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewStatusControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewStatusControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewStatusControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewStatusControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewStatusControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewStatusControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepReviewStatusIdEssential} stepReviewStatusIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepReviewStatusControllerUpdateEntity(stepReviewStatusIdEssential: StepReviewStatusIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepReviewStatusControllerUpdateEntity(stepReviewStatusIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepRevisionEssential} stepRevisionEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerCreateEntity(stepRevisionEssential: StepRevisionEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerCreateEntity(stepRevisionEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindByColumnQueryUUID} findByColumnQueryUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerFindEntity(findByColumnQueryUUID: FindByColumnQueryUUID, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerFindEntity(findByColumnQueryUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerGetAllEntities(instant: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerGetAllEntities(instant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerRemoveEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerRemoveEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StepRevisionIdEssential} stepRevisionIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stepRevisionControllerUpdateEntity(stepRevisionIdEssential: StepRevisionIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stepRevisionControllerUpdateEntity(stepRevisionIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserGroupEssential} userGroupEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userGroupControllerCreateEntity(userGroupEssential: UserGroupEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userGroupControllerCreateEntity(userGroupEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userGroupControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userGroupControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userGroupControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userGroupControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userGroupControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userGroupControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserGroupIdEssential} userGroupIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userGroupControllerUpdateEntity(userGroupIdEssential: UserGroupIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userGroupControllerUpdateEntity(userGroupIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerCreateEntity(body: object, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerCreateEntity(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerGetAllPermissionsByUserId(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerGetAllPermissionsByUserId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneIdEssential} zoneIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneControllerUpdateEntity(zoneIdEssential: ZoneIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneControllerUpdateEntity(zoneIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneLevelEssential} zoneLevelEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneLevelControllerCreateEntity(zoneLevelEssential: ZoneLevelEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneLevelControllerCreateEntity(zoneLevelEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneLevelControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneLevelControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneLevelControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneLevelControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneLevelControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneLevelControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneLevelIdEssential} zoneLevelIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneLevelControllerUpdateEntity(zoneLevelIdEssential: ZoneLevelIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneLevelControllerUpdateEntity(zoneLevelIdEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneOwnerEssential} zoneOwnerEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneOwnerControllerCreateEntity(zoneOwnerEssential: ZoneOwnerEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneOwnerControllerCreateEntity(zoneOwnerEssential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneOwnerControllerDeleteEntity(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneOwnerControllerDeleteEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneOwnerControllerGetAll(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneOwnerControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneOwnerControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneOwnerControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoneOwnerIdEssential} zoneOwnerIdEssential 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential: ZoneOwnerIdEssential, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zoneOwnerControllerUpdateEntity(zoneOwnerIdEssential, options).then((request) => request(this.axios, this.basePath));
    }
}

