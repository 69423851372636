import { Button } from "@carbon/react";
import * as Sentry from "@sentry/react";
import { XCircleIcon } from "lucide-react";

import Styles from "./sass/ErrorHandler.module.scss";

interface CustomError {
  errorTitle?: string;
  errorMessage?: string;
  hideDebug?: boolean;
}

const defaultError: CustomError = {
  errorTitle: "Critical Error",
  errorMessage:
    "A critical error occurred and the application cannot continue. This incident has been recorded. Please restart the application.",
};

const customErrors: Record<string, CustomError> = {
  "network error": {
    errorTitle: "Network Error",
    errorMessage:
      "Unable to connect to the server. Are you connected to MagMutual's private network (VPN or in-office connection)?",
    hideDebug: true,
  },
};

function findCustomError(error: Error): CustomError | undefined {
  const errorString = error.toString().toLowerCase();
  for (const [key, value] of Object.entries(customErrors)) {
    if (errorString.includes(key)) return value;
  }
  return undefined;
}

let renderedError = false;

function tryErrorReset(resetError: Function | undefined) {
  // This is quite, quite hackish!!! We are trying to get around Sentry's basically useless error reset in some cases.
  // If we encounter the same error without unmounting, we want to force a reload.
  try {
    renderedError = true;
    resetError?.();
  } catch (e) {
    window.location.reload();
  }
}

function ErrorFallback({ error, componentStack, eventId, resetError }) {
  const { errorTitle, errorMessage, hideDebug } =
    findCustomError(error) || defaultError;
  if (renderedError) {
    window.location.reload();
  }
  return (
    <div className={Styles.container}>
      <div>
        <h3 className={Styles.title}>
          <XCircleIcon aria-hidden="true" />
          &nbsp;{errorTitle || defaultError.errorTitle}
        </h3>
        <p className={Styles.message}>
          {errorMessage || defaultError.errorMessage}
        </p>
        {!hideDebug && (
          <>
            <br />
            <pre className={Styles.error}>{error.toString()}</pre>
            <br />
            <div className={Styles.inner}>
              <p>
                <span className={Styles.event}>{eventId}</span>
              </p>
              <pre className={Styles.code}>{componentStack}</pre>
            </div>
          </>
        )}
        <br />
        <Button kind="danger" onClick={() => tryErrorReset(resetError)}>
          Restart
        </Button>
        {!hideDebug && (
          <Button
            kind="secondary"
            onClick={() => {
              Sentry.showReportDialog({ eventId });
            }}
          >
            Provide Additional Information
          </Button>
        )}
      </div>
    </div>
  );
}

function ErrorHandler({ children }) {
  return (
    <Sentry.ErrorBoundary
      fallback={ErrorFallback}
      onUnmount={() => {
        renderedError = false;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorHandler;
