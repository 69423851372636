import "@wdyr/index.ts";

import NiceModal from "@ebay/nice-modal-react";
import {
  captureConsoleIntegration,
  extraErrorDataIntegration,
  reportingObserverIntegration,
} from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { RecoilRoot } from "recoil";

import Auth0ProviderWithHistory from "@components/authentication/Auth0ProviderWithHistory.tsx";
import AuthedElement from "@components/authentication/AuthedElement.tsx";
import ErrorHandler from "@components/util/ErrorHandler.tsx";

import App from "./App.tsx";
import "./index.scss";

Sentry.init({
  dsn: "https://583b0fe3d51a1299a3f5a7faf3f134b5@o1301029.ingest.sentry.io/4505795369762816",
  integrations: [
    captureConsoleIntegration({ levels: ["warn", "error"] }),
    extraErrorDataIntegration(),
    reportingObserverIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
    }),
    Sentry.metrics.metricsAggregatorIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 0.2,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 1000 * 60, // 5 minutes
      suspense: true,
    },
    mutations: {
      throwOnError: false,
      retry: 3,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ErrorHandler>
      <BrowserRouter>
        <RecoilRoot>
          <ReactFlowProvider>
            <Auth0ProviderWithHistory>
              <QueryClientProvider client={queryClient}>
                <NiceModal.Provider>
                  <SentryRoutes>
                    <Route
                      path="/*"
                      element={<AuthedElement element={App} />}
                    ></Route>
                  </SentryRoutes>
                </NiceModal.Provider>
              </QueryClientProvider>
            </Auth0ProviderWithHistory>
          </ReactFlowProvider>
        </RecoilRoot>
      </BrowserRouter>
    </ErrorHandler>
  </StrictMode>,
);
