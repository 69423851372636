import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "@carbon/react";
import * as Sentry from "@sentry/react";
import { Suspense, lazy, useEffect } from "react";

import { setAccessTokenCallback } from "@utils/api";

import "./App.scss";

const AppInner = lazy(() => import("./AppInner"));
function App() {
  const {
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      setAccessTokenCallback(async () => {
        const audience: string = "https://core-tasks-api.magmutual.com";
        const scope: string = "openid profile email offline_access";
        try {
          const token = await getAccessTokenSilently({
            authorizationParams: { audience, scope },
          });
          return token;
        } catch (e) {
          if (
            e.error === "missing_refresh_token" ||
            e.error === "invalid_grant"
          ) {
            const loc = window.location;
            await loginWithRedirect({
              appState: {
                returnTo: loc.pathname + loc.search + loc.hash,
              },
            });
          } else {
            throw e;
          }
        }
        return "";
      });
    }
    if (user) {
      let username = user.name;
      if (!username && user.given_name && user.family_name) {
        username = `${user.given_name} ${user.family_name}`;
      }
      username = username || user.nickname || undefined;
      Sentry.setUser({
        id: user.sub,
        email: user.email,
        username,
        ip_address: "{{auto}}",
      });
    } else {
      Sentry.setUser(null);
    }
  }, [isLoading, isAuthenticated, user, getAccessTokenSilently]);

  return (
    <Suspense fallback={<Loading withOverlay={false} />}>
      <AppInner />
    </Suspense>
  );
}
App.whyDidYouRender = true;

export default Sentry.withProfiler(App);
